import RevieveSDK from '../../../src/RevieveSDK';

require('popper.js');
require('bootstrap/dist/css/bootstrap.min.css');
require('bootstrap/dist/js/bootstrap.min.js');
require('bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css');
require('bootstrap-colorpicker/dist/js/bootstrap-colorpicker.min.js');
require('../css/simple-sidebar.css');
require('../css/test.css');

let configuration = {
  partnerID: 'VweadehMqO',
  userID: 'ar-demopage',
  gender: RevieveSDK.gender.FEMALE,
  productCategories: ['Blush', 'Lipstick', 'Foundation'],
};
let revieveSDK;
let imageFile;
let initialized = false;
let effectFunctions = {};
const initialState = {
  hyperpigmentationIndividuallyShown: false,
  frecklesIndividuallyShown: false,
  darkSpotsIndividuallyShown: false,
  wrinklesIndividuallyShown: false,
  acneIndividuallyShown: false,
  skinShineShown: false,
  melasmaIndividuallyShown: false,
  uvenSkinToneIndividuallyShown: false,
  rednessIndividuallyShown: false,
  skinSaggingIndividuallyShown: false,
  textureIndividuallyShown: false,
  poreDilationIndividuallyShown: false,
  highlightedKey: null,
};
let state = Object.assign({}, initialState);

const changeState = ({ propertyName, value }) => {
  const buttonIds = {
    wrinklesIndividuallyShown: 'showWrinkles',
    hyperpigmentationIndividuallyShown: 'showHyperpigmentatedPoints',
    frecklesIndividuallyShown: 'showFrecklesPoints',
    darkSpotsIndividuallyShown: 'showDarkSpotsPoints',
    acneIndividuallyShown: 'showAcnePoints',
    skinShineShown: 'showSkinShineAreas',
    melasmaIndividuallyShown: 'showMelasmaAreas',
    unevenSkinToneIndividuallyShown: 'showUnevenSkinToneAreas',
    rednessIndividuallyShown: 'showRednessAreas',
    skinSaggingIndividuallyShown: 'showSkinSaggingAreas',
    textureIndividuallyShown: 'showTextureAreas',
    poreDilationIndividuallyShown: 'showPoreDilationAreas',
  };
  // we only scroll on mobile devives
  if (propertyName !== 'highlightedKey' && window.screen.width < 576) {
    document.location.href = '#afterTitle';
  }
  state = Object.assign({}, initialState);
  state[propertyName] = value;
  Object.keys(state).forEach(stateKey => {
    const buttonId = buttonIds[stateKey];
    let buttonText = $('#' + buttonId).text();
    if (state[stateKey]) {
      buttonText = buttonText.replace('Show', 'Hide');
    } else {
      buttonText = buttonText.replace('Hide', 'Show');
    }
    $('#' + buttonId).text(buttonText);
  });
};

window.onLoadActions = () => {
  if (window.location.hostname !== 'localhost') {
    // eslint-disable-next-line no-undef
    Sentry.init({ dsn: 'https://0184b8fc4bdc4d03800a5aba4c1c7f70@sentry.io/1436419' });
  }

  $('#modalFile').modal('toggle');
  $('#applyLipstickColor').colorpicker();
  $('#applyLiplinerColor').colorpicker();
  $('#applyEyelinerColor').colorpicker();
  $('#applyEyeshadowColor').colorpicker();
  $('#applyBlushColor').colorpicker();
  $('#applyFoundationColor').colorpicker();
  loadIndependentOptionsInSelects();
};

let initializeRevieveAR = () => {
  // instantiate Revieve SDK with testing option to true
  revieveSDK = new RevieveSDK(configuration.partnerID, true);
  loadOptionsIntoSelects();
  // We configure some important information: gender
  revieveSDK.setGender(configuration.gender);
  // In AR module, we specify the containerID where we want to render automatically
  // the resulst. Note that if we don't set the containerID we need to get the
  // resultDIV manualy from the AR module and render it in the interface
  revieveSDK.AR.setContainer('afterContainer');
  // we initialize the paramenters to try products
  revieveSDK.setUserId(configuration.userID);
  revieveSDK.setCategories(configuration.productCategories);

  let components = [
    'eyes',
    'wrinkles',
    'redness',
    'shine',
    'acne',
    'texture',
    'smoothness',
    'hyperpigmentation',
    'melasma',
    'freckles',
    'dark_spots',
    'dull_skin',
    'radiance',
    'uneven_skin_tone',
    'makeup',
    'masks',
    'acne_visualization',
    'wrinkles_visualization',
    'shine_visualization',
    'hyperpigmentation_visualization',
    'freckles_visualization',
    'dark_spots_visualization',
    'melasma_visualization',
    'uneven_skin_tone_visualization',
    'pore_dilation',
    'pore_dilation_visualization',
    'skin_sagging',
    'skin_firmness',
    'redness_visualization',
    'texture_visualization',
    'skin_sagging_visualization',
  ];
  revieveSDK.setConfiguration({ components });
  $('#modalLoading').modal('show');
  // we set the image in the CV module
  revieveSDK.CV.setImage(imageFile).then(() => {
    // and analyze it
    revieveSDK.CV.analyzeImage()
      .then(() => {
        $('#modalLoading').modal('hide');
        if (!initialized) {
          $('#wrapper').toggleClass('toggled');
          initialized = true;
        } else {
          $('#beforeContainer')[0].removeChild($('#beforeContainer')[0].firstChild);
          if ($('#afterContainer')[0].firstChild) {
            $('#afterContainer')[0].removeChild($('#afterContainer')[0].firstChild);
          }
          applyEffects();
        }
        let beforeImage = document.createElement('img');
        beforeImage.src = revieveSDK.CV.getImage();
        $('#beforeContainer')[0].appendChild(beforeImage);
        revieveSDK.AR.drawOriginalImage();
        // we need to check if there are some warnings in the image analysis
        while ($('#warningList')[0].firstChild) {
          $('#warningList')[0].removeChild($('#warningList')[0].firstChild);
        }
        let statusResults = revieveSDK.CV.getStatus();
        if (statusResults && statusResults.length > 0) {
          for (const statusElement of statusResults) {
            let liObject = document.createElement('li');
            liObject.innerHTML = statusElement.description;
            $('#warningList')[0].appendChild(liObject);
          }
          $('#modalWarnings').modal('toggle');
        }
        addFindingsResults();
      })
      .catch(error => {
        if (error) {
          console.log(error);
        }
        $('#modalLoading').modal('hide');
        $('#errorDescription').html(error.message || error);
        $('#modalError').modal('show');
      });
  });
};

$('#acceptWarningsButton').click(function(e) {
  e.preventDefault();
  $('#modalWarnings').modal('toggle');
});

let applyEffects = () => {
  for (const effectActive of $('.effectApplied')) {
    let effectFunction = effectActive.id.replace('Button', '');
    effectFunctions[effectFunction]();
  }
};

$('#menu-select-file').click(function(e) {
  e.preventDefault();
  $('#modalFile').modal('toggle');
});

let loadOptionsIntoSelects = () => {
  if (initialized) {
    return;
  }
  let selects = ['#reduceRednessMasks', '#reduceHyperpigmentationMasks', '#reduceWrinklesMasks', '#brightenSkinMasks'];
  for (let select of selects) {
    let masks = RevieveSDK.masks;
    if (revieveSDK.getApiVersion() === '3') {
      masks = RevieveSDK.masks.skincare;
    }
    if (select === '#reduceHyperpigmentationMasks') {
      masks = [
        RevieveSDK.masks.skincare.SKIN,
        RevieveSDK.masks.skincare.FOREHEAD,
        RevieveSDK.masks.skincare.LEFT_CHEEK,
        RevieveSDK.masks.skincare.RIGHT_CHEEK,
      ];
    }
    for (let mask in masks) {
      if (masks[mask]) {
        $(select).append(
          $('<option>', {
            value: masks[mask],
            text: masks[mask].toUpperCase(),
          }),
        );
      }
    }
  }
};

let loadIndependentOptionsInSelects = () => {
  for (let gender in RevieveSDK.gender) {
    if (RevieveSDK.gender[gender]) {
      $('#selectGender').append(
        $('<option>', {
          value: RevieveSDK.gender[gender],
          text: RevieveSDK.gender[gender].toUpperCase(),
        }),
      );
    }
  }
};

$('#effectsMenuButton').click(function(e) {
  e.preventDefault();
  $('#wrapper').toggleClass('toggled');
});

$('#inputFile').change(function(e) {
  $('#modalFile').modal('toggle');
  $('#modalLoading').modal('toggle');
  e.preventDefault();
  let file = $('#inputFile')[0].files[0];
  if (file) {
    imageFile = file;
    $('#modalLoading').modal('hide');
    $('#modalGender').modal('toggle');
    // if you would like to convert the file into base64 value, you can use this code
    // let reader = new FileReader();
    // reader.addEventListener('load', function () {
    //   $('#modalLoading').modal('hide');
    //   imageFile = reader.result;
    //   $('#modalGenders').modal('toggle');
    // }, false);
    // reader.readAsDataURL(file);
  }
});

$('#startEffects').click(function(e) {
  e.preventDefault();
  let gender = $('#selectGender')[0].selectedOptions
    ? $('#selectGender')[0].selectedOptions[0].value
    : $('#selectGender')[0].value;
  configuration.gender = gender;
  $('#modalGender').modal('toggle');
  initializeRevieveAR();
});

let reduceEyebags = () => {
  let strenght = $('#reduceEyebagsStrenght')[0].value / 100;
  $('#reduceEyebagsStrenghtValue')[0].value = $('#reduceEyebagsStrenght')[0].value;
  revieveSDK.AR.reduceEyebags(strenght).then(() => {
    if (strenght > 0) {
      $('#reduceEyebagsButton').addClass('effectApplied');
    } else {
      $('#reduceEyebagsButton').removeClass('effectApplied');
    }
  });
};

effectFunctions.reduceEyebags = reduceEyebags;

$('#reduceEyebagsStrenght').change(function(e) {
  e.preventDefault();
  reduceEyebags();
});

let reduceCrowsFeet = () => {
  let strenght = $('#reduceCrowsFeetStrenght')[0].value / 100;
  $('#reduceCrowsFeetStrenghtValue')[0].value = $('#reduceCrowsFeetStrenght')[0].value;
  revieveSDK.AR.reduceCrowsFeet(strenght).then(() => {
    if (strenght > 0) {
      $('#reduceCrowsFeetButton').addClass('effectApplied');
    } else {
      $('#reduceCrowsFeetButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.reduceCrowsFeet = reduceCrowsFeet;

$('#reduceCrowsFeetStrenght').change(function(e) {
  e.preventDefault();
  reduceCrowsFeet();
});

let reduceDarkcircles = () => {
  let strenght = $('#reduceDarkcirclesStrenght')[0].value / 100;
  $('#reduceDarkcirclesStrenghtValue')[0].value = $('#reduceDarkcirclesStrenght')[0].value;
  revieveSDK.AR.reduceDarkcircles(strenght).then(() => {
    if (strenght > 0) {
      $('#reduceDarkcirclesButton').addClass('effectApplied');
    } else {
      $('#reduceDarkcirclesButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.reduceDarkcircles = reduceDarkcircles;

$('#reduceDarkcirclesStrenght').change(function(e) {
  e.preventDefault();
  reduceDarkcircles();
});

let reduceRedness = () => {
  if ($('#reduceRednessMasks')[0].selectedOptions.length <= 0) {
    alert('Please, select at least one area to apply the effect');
    return;
  }
  let selectedMasks = [];
  for (let option of $('#reduceRednessMasks')[0].selectedOptions) {
    selectedMasks.push(option.value);
  }
  let strenght = $('#reduceRednessStrenght')[0].value / 100;
  $('#reduceRednessStrenghtValue')[0].value = $('#reduceRednessStrenght')[0].value;
  revieveSDK.AR.reduceRedness(strenght, selectedMasks).then(() => {
    if (strenght > 0) {
      $('#reduceRednessButton').addClass('effectApplied');
    } else {
      $('#reduceRednessButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.reduceRedness = reduceRedness;

$('#reduceRednessApply').click(e => {
  e.preventDefault();
  reduceRedness();
});

$('#reduceRednessStrenght').change(e => {
  e.preventDefault();
  reduceRedness();
});

let reduceHyperpigmentation = () => {
  if ($('#reduceHyperpigmentationMasks')[0].selectedOptions.length <= 0) {
    alert('Please, select at least one area to apply the effect');
    return;
  }
  let selectedMasks = [];
  for (let option of $('#reduceHyperpigmentationMasks')[0].selectedOptions) {
    selectedMasks.push(option.value);
  }
  let strenght = $('#reduceHyperpigmentationStrenght')[0].value / 100;
  $('#reduceHyperpigmentationStrenghtValue')[0].value = $('#reduceHyperpigmentationStrenght')[0].value;
  revieveSDK.AR.reduceHyperpigmentation(strenght, selectedMasks).then(() => {
    if (strenght > 0) {
      $('#reduceHyperpigmentationButton').addClass('effectApplied');
    } else {
      $('#reduceHyperpigmentationButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.reduceHyperpigmentation = reduceHyperpigmentation;

$('#reduceHyperpigmentationApply').click(e => {
  e.preventDefault();
  reduceHyperpigmentation();
});

$('#reduceHyperpigmentationStrenght').change(e => {
  e.preventDefault();
  reduceHyperpigmentation();
});

let reduceWrinkles = () => {
  if ($('#reduceWrinklesMasks')[0].selectedOptions.length <= 0) {
    alert('Please, select at least one area to apply the effect');
    return;
  }
  let selectedMasks = [];
  for (let option of $('#reduceWrinklesMasks')[0].selectedOptions) {
    selectedMasks.push(option.value);
  }
  let strenght = $('#reduceWrinklesStrenght')[0].value / 100;
  $('#reduceWrinklesStrenghtValue')[0].value = $('#reduceWrinklesStrenght')[0].value;
  revieveSDK.AR.reduceWrinkles(strenght, selectedMasks).then(() => {
    if (strenght > 0) {
      $('#reduceWrinklesButton').addClass('effectApplied');
    } else {
      $('#reduceWrinklesButton').removeClass('effectApplied');
    }
  });
};

effectFunctions.reduceWrinkles = reduceWrinkles;

$('#reduceWrinklesApply').click(e => {
  e.preventDefault();
  reduceWrinkles();
});

$('#reduceWrinklesStrenght').change(e => {
  e.preventDefault();
  reduceWrinkles();
});

let brightenSkin = () => {
  if ($('#brightenSkinMasks')[0].selectedOptions.length <= 0) {
    alert('Please, select at least one area to apply the effect');
    return;
  }
  let selectedMasks = [];
  for (let option of $('#brightenSkinMasks')[0].selectedOptions) {
    selectedMasks.push(option.value);
  }
  let strenght = $('#brightenSkinStrenght')[0].value / 100;
  $('#brightenSkinStrenghtValue')[0].value = $('#brightenSkinStrenght')[0].value;
  revieveSDK.AR.brightenSkin(strenght, selectedMasks).then(() => {
    if (strenght > 0) {
      $('#brightenSkinButton').addClass('effectApplied');
    } else {
      $('#brightenSkinButton').removeClass('effectApplied');
    }
  });
};

effectFunctions.brightenSkin = brightenSkin;

$('#brightenSkinApply').click(e => {
  e.preventDefault();
  brightenSkin();
});

$('#brightenSkinStrenght').change(e => {
  e.preventDefault();
  brightenSkin();
});

let applyLipstick = () => {
  let color = $('#applyLipstickColor')[0].value;
  if (!color) {
    alert('Please, select the lipstick color you want to apply');
    return;
  }
  let strenght = $('#applyLipstickStrenght')[0].value / 100;
  $('#applyLipstickStrenghtValue')[0].value = $('#applyLipstickStrenght')[0].value;
  revieveSDK.AR.applyLipstick(strenght, color).then(() => {
    if (strenght > 0) {
      $('#applyLipstickButton').addClass('effectApplied');
    } else {
      $('#applyLipstickButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyLipstick = applyLipstick;

$('#applyLipstickApply').click(e => {
  e.preventDefault();
  applyLipstick();
});
$('#applyLipstickStrenght').change(e => {
  e.preventDefault();
  applyLipstick();
});

let applyLipliner = () => {
  let color = $('#applyLiplinerColor')[0].value;
  if (!color) {
    alert('Please, select the lipliner color you want to apply');
    return;
  }
  let strenght = $('#applyLiplinerStrenght')[0].value / 100;
  $('#applyLiplinerStrenghtValue')[0].value = $('#applyLiplinerStrenght')[0].value;
  revieveSDK.AR.applyLipliner(strenght, color).then(() => {
    if (strenght > 0) {
      $('#applyLiplinerButton').addClass('effectApplied');
    } else {
      $('#applyLiplinerButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyLipliner = applyLipliner;

$('#applyLiplinerApply').click(e => {
  e.preventDefault();
  applyLipliner();
});
$('#applyLiplinerStrenght').change(e => {
  e.preventDefault();
  applyLipliner();
});

let applyEyeliner = () => {
  let color = $('#applyEyelinerColor')[0].value;
  if (!color) {
    alert('Please, select the eyeliner color you want to apply');
    return;
  }
  let strenght = $('#applyEyelinerStrenght')[0].value / 100;
  $('#applyEyelinerStrenghtValue')[0].value = $('#applyEyelinerStrenght')[0].value;
  revieveSDK.AR.applyEyeliner(strenght, color).then(() => {
    if (strenght > 0) {
      $('#applyEyelinerButton').addClass('effectApplied');
    } else {
      $('#applyEyelinerButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyEyeliner = applyEyeliner;

$('#applyEyelinerApply').click(e => {
  e.preventDefault();
  applyEyeliner();
});
$('#applyEyelinerStrenght').change(e => {
  e.preventDefault();
  applyEyeliner();
});

let applyEyeshadow = () => {
  let color = $('#applyEyeshadowColor')[0].value;
  if (!color) {
    alert('Please, select the eyeshadow color you want to apply');
    return;
  }
  let strenght = $('#applyEyeshadowStrenght')[0].value / 100;
  $('#applyEyeshadowStrenghtValue')[0].value = $('#applyEyeshadowStrenght')[0].value;
  revieveSDK.AR.applyEyeshadow(strenght, color).then(() => {
    if (strenght > 0) {
      $('#applyEyeshadowButton').addClass('effectApplied');
    } else {
      $('#applyEyeshadowButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyEyeshadow = applyEyeshadow;

$('#applyEyeshadowApply').click(e => {
  e.preventDefault();
  applyEyeshadow();
});
$('#applyEyeshadowStrenght').change(e => {
  e.preventDefault();
  applyEyeshadow();
});

let applyBlush = () => {
  let color = $('#applyBlushColor')[0].value;
  if (!color) {
    alert('Please, select the blush color you want to apply');
    return;
  }
  let strenght = $('#applyBlushStrenght')[0].value / 100;
  $('#applyBlushStrenghtValue')[0].value = $('#applyBlushStrenght')[0].value;
  revieveSDK.AR.applyBlush(strenght, color).then(() => {
    if (strenght > 0) {
      $('#applyBlushButton').addClass('effectApplied');
    } else {
      $('#applyBlushButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyBlush = applyBlush;

$('#applyBlushApply').click(e => {
  e.preventDefault();
  applyBlush();
});

$('#applyBlushStrenght').change(e => {
  e.preventDefault();
  applyBlush();
});

let applyFoundation = () => {
  let color = $('#applyFoundationColor')[0].value;
  if (!color) {
    alert('Please, select the foundation color you want to apply');
    return;
  }
  let strenght = $('#applyFoundationStrenght')[0].value / 100;
  $('#applyFoundationStrenghtValue')[0].value = $('#applyFoundationStrenght')[0].value;
  revieveSDK.AR.applyFoundation(strenght, color).then(() => {
    if (strenght > 0) {
      $('#applyFoundationButton').addClass('effectApplied');
    } else {
      $('#applyFoundationButton').removeClass('effectApplied');
    }
  });
};
effectFunctions.applyFoundation = applyFoundation;

$('#applyFoundationApply').click(e => {
  e.preventDefault();
  applyFoundation();
});

$('#applyFoundationStrenght').change(function(e) {
  e.preventDefault();
  applyFoundation();
});

$('#selectNewPicture').click(function(e) {
  e.preventDefault();
  $('#modalFile').modal('toggle');
});

let addFindingsResults = () => {
  let findings = revieveSDK.CV.getFindings(true);
  $('#findingsResults').empty();
  for (const findingKey in findings) {
    if (findings[findingKey]) {
      let findingKeyText = findingKey;
      if (findingKey === 'unevenSkinTone') {
        findingKeyText = 'uneven skin tone';
      }
      if (findingKey === 'skinShine') {
        findingKeyText = 'skin shine';
      }
      if (findingKey === 'poreDilation') {
        findingKeyText = 'pore dilation';
      }
      if (findingKey === 'skinSagging') {
        findingKeyText = 'skin sagging';
      }
      if (findingKey === 'skinFirmness') {
        findingKeyText = 'skin firmness';
      }
      const element = findings[findingKey];
      let findingButton = '';
      if (typeof element.value !== 'number') {
        findingButton = `<div id="highlight${findingKey}Button" class="findingButton findingNotNumeric">
          <h3>${findingKey.toUpperCase()}</h3>
          <div>
            <p>${element && element.value ? element.value.toUpperCase() : ''} </p>
          <div>
        </div>`;
      } else {
        findingButton = `<div class="findingButton">
          <a id="highlight${findingKey}Button" data-toggle="collapse" 
            href="#highlight${findingKey}Options" role="button" aria-expanded="false" 
            aria-controls="highlight${findingKey}Options">
            <h3>${findingKeyText.toUpperCase()}</h3>
            <div>
              <p>${element.value} % </p>
              <div class="findingBar" style="width:${element.value}%;"></div>
            <div>
          </a>
          <div class="collapse" id="highlight${findingKey}Options">
            <p id="highlight${findingKey}MasksText">Areas detected. Select to highlight.</p>
              <select multiple class="form-control" id="highlight${findingKey}Masks"></select>
              ${
                element.masks && element.masks.length > 0
                  ? '<button id="highlight' +
                    findingKey +
                    '" class="highlightButton">Highlight selected areas</button><button id="highlightCustomized' +
                    findingKey +
                    '" class="highlightButton">Highlight selected areas (customized)</button><button id="highlight' +
                    findingKey +
                    'AreaValues" class="highlightButton">Highlight areas value</button>'
                  : ''
              }
            ${
              findingKey === 'wrinkles'
                ? '<button id="showWrinkles" class="highlightButton">Show worst wrinkles</button>'
                : ''
            }
            ${
              findingKey === 'hyperpigmentation'
                ? '<button id="showHyperpigmentatedPoints" class="highlightButton">Show hyperpigmentated points</button>'
                : ''
            }
            ${
              findingKey === 'freckles'
                ? '<button id="showFrecklesPoints" class="highlightButton">Show freckles</button>'
                : ''
            }
            ${
              findingKey === 'darkSpots'
                ? '<button id="showDarkSpotsPoints" class="highlightButton">Show dark spots</button>'
                : ''
            }
            ${
              findingKey === 'skinShine'
                ? '<button id="showSkinShineAreas" class="highlightButton">Show skin shine areas</button>'
                : ''
            }
            ${
              findingKey === 'melasma'
                ? '<button id="showMelasmaAreas" class="highlightButton">Show melasma areas</button>'
                : ''
            }
            ${
              findingKey === 'unevenSkinTone'
                ? '<button id="showUnevenSkinToneAreas" class="highlightButton">Show uneven skin tone areas</button>'
                : ''
            }
            ${findingKey === 'acne' ? '<button id="showAcnePoints" class="highlightButton">Show acne</button>' : ''}
            ${
              findingKey === 'redness'
                ? '<button id="showRednessAreas" class="highlightButton">Show areas with redness</button>'
                : ''
            }
            ${
              findingKey === 'texture'
                ? '<button id="showTextureAreas" class="highlightButton">Show textured areas</button>'
                : ''
            }
            ${
              findingKey === 'skinSagging'
                ? '<button id="showSkinSaggingAreas" class="highlightButton">Show areas with skin sagging</button>'
                : ''
            }
            ${
              findingKey === 'poreDilation'
                ? '<button id="showPoreDilationAreas" class="highlightButton">Show pore dilation areas</button>'
                : ''
            }
          </div>
        </div>`;
      }
      $('#findingsResults').append(findingButton);
      if (findingKey === 'wrinkles') {
        $('#showWrinkles').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.wrinklesIndividuallyShown) revieveSDK.AR.highlightWrinklesIndividually(null, selectedMasks);
            changeState({ propertyName: 'wrinklesIndividuallyShown', value: !state.wrinklesIndividuallyShown });
          });
        });
      }
      if (findingKey === 'hyperpigmentation') {
        $('#showHyperpigmentatedPoints').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.hyperpigmentationIndividuallyShown)
              revieveSDK.AR.highlightHyperpigmentationIndividually(null, selectedMasks);
            changeState({
              propertyName: 'hyperpigmentationIndividuallyShown',
              value: !state.hyperpigmentationIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'freckles') {
        $('#showFrecklesPoints').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.frecklesIndividuallyShown) revieveSDK.AR.highlightFrecklesIndividually(null, selectedMasks);
            changeState({
              propertyName: 'frecklesIndividuallyShown',
              value: !state.frecklesIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'darkSpots') {
        $('#showDarkSpotsPoints').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.darkSpotsIndividuallyShown) revieveSDK.AR.highlightDarkSpotsIndividually(null, selectedMasks);
            changeState({
              propertyName: 'darkSpotsIndividuallyShown',
              value: !state.darkSpotsIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'melasma') {
        $('#showMelasmaAreas').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.melasmaIndividuallyShown) revieveSDK.AR.highlightMelasmaIndividually(null, selectedMasks);
            changeState({
              propertyName: 'melasmaIndividuallyShown',
              value: !state.melasmaIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'unevenSkinTone') {
        $('#showUnevenSkinToneAreas').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.unevenSkinToneIndividuallyShown)
              revieveSDK.AR.highlightUnevenSkinToneIndividually(null, selectedMasks);
            changeState({
              propertyName: 'unevenSkinToneIndividuallyShown',
              value: !state.unevenSkinToneIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'redness') {
        $('#showRednessAreas').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.rednessIndividuallyShown) revieveSDK.AR.highlightRednessIndividually(null, selectedMasks);
            changeState({
              propertyName: 'rednessIndividuallyShown',
              value: !state.rednessIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'texture') {
        $('#showTextureAreas').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.textureIndividuallyShown) revieveSDK.AR.highlightTextureIndividually(null, selectedMasks);
            changeState({
              propertyName: 'textureIndividuallyShown',
              value: !state.textureIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'poreDilation') {
        $('#showPoreDilationAreas').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.poreDilationIndividuallyShown) revieveSDK.AR.highlightPoreDilationIndividually()
              .then(animationStyle => $('.revieveSDKResults').css(animationStyle.in));
            changeState({
              propertyName: 'poreDilationIndividuallyShown',
              value: !state.poreDilationIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'skinSagging') {
        $('#showSkinSaggingAreas').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.skinSaggingIndividuallyShown) revieveSDK.AR.highlightSkinSaggingIndividually();
            changeState({
              propertyName: 'skinSaggingIndividuallyShown',
              value: !state.skinSaggingIndividuallyShown,
            });
          });
        });
      }
      if (findingKey === 'acne') {
        $('#showAcnePoints').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          let selectedMasks = null;
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              if (!selectedMasks) selectedMasks = [];
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.acneIndividuallyShown) revieveSDK.AR.highlightAcneIndividually(null, selectedMasks);
            changeState({ propertyName: 'acneIndividuallyShown', value: !state.acneIndividuallyShown });
          });
        });
      }
      if (findingKey === 'skinShine') {
        $('#showSkinShineAreas').on('click', e => {
          e.preventDefault();
          e.stopPropagation();
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (!state.skinShineShown) revieveSDK.AR.highlightSkinShine();
            changeState({ propertyName: 'skinShineShown', value: !state.skinShineShown });
          });
        });
      }

      if (element.masks && element.masks.length > 0) {
        $('#highlight' + findingKey + 'Masks').empty();
        $('#highlight' + findingKey + 'Masks').append(
          $('<option>', {
            value: 'worst',
            text: 'THE WORST ONE / INDIVIDUALLY ALL AREAS',
          }),
        );
        element.masks.map(mask =>
          $('#highlight' + findingKey + 'Masks').append(
            $('<option>', {
              value: mask,
              text: mask.toUpperCase(),
            }),
          ),
        );
        $('#highlight' + findingKey + 'Masks').on('change', function(e) {
          e.preventDefault();
          changeState({ propertyName: 'highlightedKey', value: '' });
        });
        $('#highlight' + findingKey).on('click', function(e) {
          e.preventDefault();
          let selectedMasks = [];
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (state.highlightedKey !== findingKey) {
              revieveSDK.AR['highlight' + capitalizeString(findingKey)](selectedMasks).then(animationStyle => {
                // we only scroll on mobile devives
                if (window.screen.width < 576) {
                  document.location.href = '#afterTitle';
                }
                $('.revieveSDKResults').css(animationStyle.in);
              });
              changeState({ propertyName: 'highlightedKey', value: findingKey });
            } else {
              changeState({ propertyName: 'highlightedKey', value: '' });
            }
          });
        });
        $('#highlightCustomized' + findingKey).on('click', function(e) {
          e.preventDefault();
          let selectedMasks = [];
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (state.highlightedKey !== findingKey) {
              revieveSDK.AR['highlight' + capitalizeString(findingKey)](selectedMasks, {
                stroke: { color: '#3cadb2', width: 3, lineDash: [3, 6] },
              }).then(animationStyle => {
                // we only scroll on mobile devives
                if (window.screen.width < 576) {
                  document.location.href = '#afterTitle';
                }
                $('.revieveSDKResults').css(animationStyle.in);
              });
              changeState({ propertyName: 'highlightedKey', value: findingKey });
            } else {
              changeState({ propertyName: 'highlightedKey', value: '' });
            }
          });
        });
        $('#highlight' + findingKey + 'AreaValues').on('click', function(e) {
          e.preventDefault();
          let selectedMasks = [];
          for (let option of $('#highlight' + findingKey + 'Masks')[0].selectedOptions) {
            if (option.value === 'worst') {
              selectedMasks = null;
              break;
            } else {
              selectedMasks.push(option.value);
            }
          }
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            if (state.highlightedKey !== findingKey + 'AreaValues') {
              revieveSDK.AR['highlight' + capitalizeString(findingKey) + 'AreaValues'](null, selectedMasks);
              // we only scroll on mobile devives
              if (window.screen.width < 576) {
                document.location.href = '#afterTitle';
              }
              changeState({ propertyName: 'highlightedKey', value: findingKey + 'AreaValues' });
            } else {
              changeState({ propertyName: 'highlightedKey', value: '' });
            }
          });
        });
      } else {
        $('#highlight' + findingKey + 'Masks').hide();
        $('#highlight' + findingKey + 'MasksText').hide();
      }
      if (typeof element.value !== 'number') {
        $('#highlight' + findingKey + 'Button').click(function(e) {
          e.preventDefault();
          let key = capitalizeString(findingKey);
          revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
            $('.revieveSDKResults').css(animationStyleOut.out);
            revieveSDK.AR['highlight' + key]().then(animationStyle => {
              // we only scroll on mobile devives
              if (window.screen.width < 576) {
                document.location.href = '#afterTitle';
              }
              $('.revieveSDKResults').css(animationStyle.in);
            });
            changeState({ propertyName: 'highlightedKey', value: '' });
          });
        });
      }
    }
  }
  let skinFoundation = revieveSDK.CV.getSkinFoundation();
  if (skinFoundation) {
    let findingButton = `<div id="skinFoundationButton" class="findingButton findingNotNumeric">
        <h3>FOUNDATION MATCHING</h3>
        <div>
          <div style="margin-top:5px; widht: 100%; background-color: ${skinFoundation.hex}; color: white; font-size: 0.8rem; text-align: center">
          ${skinFoundation.hex}
          </div>
          <div style="font-size: 0.7rem;">Skintone: ${skinFoundation.skintone}</div>
          <div style="font-size:8px">In Fitzpatrick scale</div>
        <div>
      </div>`;
    $('#findingsResults').append(findingButton);
    $('#skinFoundationButton').click(function(e) {
      e.preventDefault();
      revieveSDK.AR.dehighlightAll().then(animationStyleOut => {
        $('.revieveSDKResults').css(animationStyleOut.out);
        revieveSDK.AR.highlightSkinFoundation().then(animationStyle => {
          // we only scroll on mobile devives
          if (window.screen.width < 576) {
            document.location.href = '#afterTitle';
          }
          $('.revieveSDKResults').css(animationStyle.in);
        });
        changeState({ propertyName: 'highlightedKey', value: '' });
      });
    });
  }
};

let capitalizeString = text => text[0].toUpperCase() + text.substring(1);

$('#tryProductButton').click(function(e) {
  e.preventDefault();
  $('#modalProductsContainer').empty();
  revieveSDK.PR.getRecommendedProducts().then(products => {
    if (!products) {
      return null;
    }
    for (const productKey in products) {
      if (products[productKey] && revieveSDK.AR.canVisualizeProduct(products[productKey])) {
        let product = products[productKey];
        let productCard = `
          <div class="productCard">
            <div class="row productDescription">
              <div class="col col-3 productImage" style="background-image: url(${product.image})">
                &nbsp;
              </div>
              <div class="col col-9">
                <h3>${product.category}</h3>
                <p>${product.name}</p>
              </div>
            </div>
            <div class="row" style="text-align: right;">
              <div class="col col-12">
                <button id="testProduct${product.id}Button" type="button" class="btn btn-link">Test it!</button>
              </div>
            </div>
          </div>
        `;
        $('#modalProductsContainer').append(productCard);
        $('#testProduct' + product.id + 'Button').click(function(eP) {
          eP.preventDefault();
          testProduct(product.id);
        });
      }
    }
    $('#modalProducts').modal('show');
    return true;
  });
});

$('#closeModalProducts').click(function(e) {
  e.preventDefault();
  $('#modalProducts').modal('hide');
});

// eslint-disable-next-line
let testProduct = productId => {
  let products = revieveSDK.PR.getLastRecommendedProducts();
  for (const productKey in products) {
    if (products[productKey]) {
      const element = products[productKey];
      if (element.id === productId) {
        revieveSDK.AR.visualizeProduct(element).then(() => {
          let colorInfo = revieveSDK.AR.getColorInfoFromProduct(element);
          let defaultStrength = revieveSDK.AR.getDefaultStrengthFromProduct(element);
          $('#apply' + capitalizeString(element.category) + 'Color').val(colorInfo);
          $('#apply' + capitalizeString(element.category) + 'Color').trigger('change');
          $('#apply' + capitalizeString(element.category) + 'Strenght').val(parseInt(defaultStrength * 100, 10));
          $('#apply' + capitalizeString(element.category) + 'Button').addClass('effectApplied');
          $('#apply' + capitalizeString(element.category) + 'StrenghtValue')[0].value = parseInt(
            defaultStrength * 100,
            10,
          );
          $('#modalProducts').modal('hide');
        });
      }
    }
  }
};

$('#resetButton').click(function(e) {
  e.preventDefault();
  revieveSDK.AR.reset();
  for (const effectActive of $('.effectApplied')) {
    $('#' + effectActive.id).removeClass('effectApplied');
  }
});

$('#anonymizeButton').click(function(e) {
  e.preventDefault();
  revieveSDK.CV.getAnonymizeImage().then(anonymizedImage => {
    const element = document.createElement('a');
    element.setAttribute('href', anonymizedImage);
    element.setAttribute('download', 'anonymizedImage');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
});

$('#getResultsImageButton').click(async function(e) {
  e.preventDefault();
  const image = await revieveSDK.AR.getResultsImage();
  const element = document.createElement('a');
  element.setAttribute('href', image);
  element.setAttribute('download', 'resultsImage');
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
});
